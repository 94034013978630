<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export default {
  data() {
    return{
      locale: zhCN,
    }
  }
}
</script>

<style lang="less">
*::-webkit-scrollbar {
  display: none;
}
body{
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  -webkit-text-size-adjust: none !important;
}
</style>
