import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index/index'),
  },
  // 任务宝
  {
    path: '/workFission',
    name: 'workFissionIndex',
    component: () => import(/* webpackChunkName: "workFission" */ '@/views/workFission/index'),
  },
  {
    path: '/workFission/speed',
    component: () => import(/* webpackChunkName: "workFission" */ '@/views/workFission/speed'),
  },
  // 抽奖活动
  {
    path: '/lottery',
    name: 'lotteryIndex',
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/lottery/index'),
  },
  // 抽奖活动——规则说明
  {
    path: '/explain',
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/lottery/explain'),
  },
  // 互动雷达
  {
    path: '/radar',
    name: 'radar',
    component: () => import(/* webpackChunkName: "radar" */ '@/views/radar/index'),
  },
  // 群打卡
  {
    path: '/roomClockIn',
    name: 'roomClockIn',
    component: () => import(/* webpackChunkName: "roomClockIn" */ '@/views/roomClockIn/index'),
  },
  // 门店活码
  {
    path: '/shopCode',
    name: 'shopCodeIndex',
    component: () => import(/* webpackChunkName: "shopCode" */ '@/views/shopCode/index'),
  },
  // 群裂变
  {
    path: '/roomFission',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/index'),
  },
  // 群裂变海报
  {
    path: '/roomFission/poster',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/poster'),
  },
  // 群裂变——助力进度
  {
    path: '/roomFission/speed',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/speed'),
  },
  // 无限拉群
  {
    path: '/roomInfinitePull',
    component: () => import(/* webpackChunkName: "roomInfinitePull" */ '@/views/roomInfinitePull/index'),
  },
  // 引流链接 小程序
  {
    path: '/drainageLink',
    component: () => import(/* webpackChunkName: "drainageLink" */ '@/views/drainageLink/index'),
  },
  // 超级活码 小程序
  {
    path: '/superCode',
    component: () => import(/* webpackChunkName: "superCode" */ '@/views/superCode/index'),
  },
  // 接粉计划 小程序
  {
    path: '/receiveFans',
    component: () => import(/* webpackChunkName: "receiveFans" */ '@/views/receiveFans/index'),
  },
  // // 引流链接 h5
  // {
  //   path: '/drainageLink/h5',
  //   component: () => import(/* webpackChunkName: "drainageLinkH5" */ '@/views/drainageLink/h5'),
  // },
  // 落地页-企服
  {
    path: '/landingPage',
    component: () => import(/* webpackChunkName: "landingPage" */ '@/views/landingPage/index'),
  },
  // 落地页-代理记账
  {
    path: '/landingPage/bookkeepingAgency',
    component: () => import(/* webpackChunkName: "bookkeepingAgency" */ '@/views/landingPage/bookkeepingAgency'),
  },
  {
    path: '/customLanding',
    component: () => import(/* webpackChunkName: "customLanding" */ '@/views/customLanding/index'),
  },
  {
    path: '/landing',
    component: () => import(/* webpackChunkName: "customLanding" */ '@/views/landing/index'),
  },
  {
    path: '/groundPage',
    component: () => import(/* webpackChunkName: "customLanding" */ '@/views/groundPage/index'),
  },
  {
    path: '/zhuyuanArmyIndex',
    component: () => import(/* webpackChunkName: "customLanding" */ '@/views/army/index'),
  },
  {
    path: '/zhuyuanArmyList',
    component: () => import(/* webpackChunkName: "customLanding" */ '@/views/army/list'),
  },
  {
    path: '/zhuyuanArmyDetail',
    component: () => import(/* webpackChunkName: "customLanding" */ '@/views/army/detail'),
  },
  {
    path: '/zhuyuanArmyCity',
    component: () => import(/* webpackChunkName: "customLanding" */ '@/views/army/city'),
  },
  {
    path: '/zhuyuanArmyHome',
    component: () => import(/* webpackChunkName: "customLanding" */ '@/views/army/home'),
  },
  {
    path: '/zhuyuanArmyData',
    component: () => import(/* webpackChunkName: "customLanding" */ '@/views/army/data'),
  },
  {
    path: '/zhuyuanArmyDataDetail',
    component: () => import(/* webpackChunkName: "customLanding" */ '@/views/army/dataDetail'),
  },
  {
    path: '/zhuyuanArmyDataList',
    component: () => import(/* webpackChunkName: "customLanding" */ '@/views/army/dataList'),
  }
]
const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
